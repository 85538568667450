import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/common.css'
import '@/assets/js/jquery-3.2.1.min.js' //jquery
import '@/assets/js/cch5upload.js' // 视频上传引用
import '@/assets/js/cch5uploadAll.js' // 视频上传引用
import '@/assets/css/element.scss'
import '@/assets/css/quill.bubble.css'
import '@/assets/css/quill.snow.css'
import '@/assets/css/quill.core.css'
import '@/assets/iconFont/iconfont.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Vant from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.config.warnHandler = () => null
app.use(Vant);

// Lazyload 指令需要单独进行注册
// app.use(Vant.Lazyload);
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
