import { createRouter, createWebHashHistory } from 'vue-router'
import { getCenterInfo, getViewStatus } from '@/api/public'
import { ElMessage } from 'element-plus';
const routes = [
  //后台
  {
    path:'/login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/activity',
    meta: {
      title: '包容页'
    },
    component: () => import('@/views/home'),
    children: [
      {
        path: '/home/activity',
        name: 'activity',
        meta: {
          title: ['活动管理'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/index')
      },
      {
        path: '/home/activity/add',
        name: 'activityAdd',
        meta: {
          title: ['活动管理', '新建活动'],
          routers: ['/home/activity'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/add.vue')
      },
      {
        path: '/home/activity/s',
        name: 'activityS',
        meta: {
          title: ['活动管理', '学员管理'],
          routers: ['/home/activity'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/student.vue')
      },
      {
        path: '/home/activity/course',
        name: 'activityCourse',
        meta: {
          title: ['活动管理', '课程管理'],
          routers: ['/home/activity'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/course.vue')
      },
      {
        path: '/home/activity/course/e',
        name: 'activityCourseE',
        meta: {
          title: ['活动管理', '课程管理','编辑课程'],
          routers: ['/home/activity/course'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/courseEdit.vue')
      },
      {
        path: '/home/activity/c',
        name: 'activityC',
        meta: {
          title: ['活动管理', '证书管理'],
          routers: ['/home/activity'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/cetificate.vue')
      },
      {
        path: '/home/activity/c/congifg',
        name: 'config',
        meta: {
          title: ['活动管理', '证书管理','模版设置'],
          routers: ['/home/activity/c'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/config.vue')
      },
      // 证书查询
      {
        path: '/home/search',
        name: 'search',
        meta: {
          title: ['证书查询'],
          isCrumb: true 
        },
        component: () => import('@/views/cetificateSearch/index'),
      },
      // 订单管理
      {
        path: '/home/order',
        name: 'order',
        meta: {
          title: ['订单管理'],
          isCrumb: true 
        },
        component: () => import('@/views/order/index'),
      },
      // 直播
      {
        path: '/home/live',
        name: 'live',
        meta: {
          title: ['公益直播'],
          isCrumb: true 
        },
        component: () => import('@/views/live/index'),
      },
      {
        path: '/home/live/add',
        name: 'liveAdd',
        meta: {
          title: ['公益直播', '发布直播'],
          routers: ['/home/live'],
          isCrumb: true 
        },
        component: () => import('@/views/live/add.vue')
      },
      // 编辑录播
      {
        path: '/home/live/editre',
        name: 'liveAditre',
        meta: {
          title: ['公益直播', '编辑'],
          routers: ['/home/live'],
          isCrumb: true 
        },
        component: () => import('@/views/live/editRecord.vue')
      },
      // 用户管理
      {
        path: '/home/user',
        name: 'user',
        meta: {
          title: ['用户管理'],
          isCrumb: true 
        },
        component: () => import('@/views/user/index'),
      },
      {
        path: '/home/user/record',
        name: 'record',
        meta: {
          title: ['用户管理', '学习记录'],
          routers: ['/home/user'],
          isCrumb: true 
        },
        component: () => import('@/views/user/record'),
      },
      // 网站维护
      {
        path: '/home/maintenance',
        name: 'maintenance',
        meta: {
          title: ['网站维护'],
          isCrumb: true 
        },
        component: () => import('@/views/maintenance/index'),
      },
       // 字典管理
       {
        path: '/home/dictionary',
        name: 'dictionary',
        meta: {
          title: ['字典管理'],
          isCrumb: true 
        },
        component: () => import('@/views/dictionary/index'),
      },
       // 公告管理
       {
        path: '/home/notice',
        name: 'notice',
        meta: {
          title: ['公告管理'],
          isCrumb: true 
        },
        component: () => import('@/views/notice/index'),
      },
      {
        path: '/home/notice/publish',
        name: 'noticepublish',
        meta: {
          title: ['公告管理','发布'],
          routers: ['/home/notice'],
          isCrumb: true 
        },
        component: () => import('@/views/notice/publish'),
      },
    ]
  },

    // pc
    {
      path:'/p',
      component: () => import('@/views/pc/index.vue')
    },
    {
      path:'/pdetail',
      component: () => import('@/views/pc/detail.vue')
    },
    // h5
    {
      path:'/m',
      component: () => import('@/views/mobile/index.vue')
    },
    {
      path:'/mdetail',
      component: () => import('@/views/mobile/detail.vue')
    },
  
    {
      path: '/',
      redirect: '/p'
    },
  
    {
      path:'/preview',
      component: () => import('@/views/activity/preview.vue')
    },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  }
});


function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token');
  if (!token && to.path.startsWith('/home')) {
    next({ path: '/login' });
  }else{
    next()
  }

  if (to.fullPath.includes('/p') || to.fullPath.includes('/m')) {
    document.title = '证书查询'
  } else{
     document.title = '证书管理系统'
  }

});

router.beforeEach((to, from, next) => {

  if (isMobile()) {
    // 重定向到移动端
    if (!to.path.startsWith('/m')) {
      next('/m');
    } else {
      next();
    }
  } else {
    // 重定向到pc端
    if (to.path.startsWith('/m')) {
      next('/p');
    } else {
      next();
    }
  }

});

// 路由守卫--start
// router.beforeEach((to, from, next) => {
//   getViewStatus({pid: 0}).then(res => {
//     if (!res.data) return ElMessage.error(res.msg);
//     // 未登录
//     if (!res.data.loginStatus) {
//       sessionStorage.clear();
//     };
//     // 登录
//     if (res.data.loginStatus) {
//       getCenterInfo({roleTypeId: 0, roleId: 5}).then(res => {
//         if (res.success) {
//           sessionStorage.setItem('loginMessage', JSON.stringify(res.data));
//           next();
//         } else {
//           // 清除所有登录信息
//           sessionStorage.clear();
//         };
//       });
//     };
//   });
// });

// 路由守卫--end
export default router
