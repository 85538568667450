import service from '@/utils/request'

//  文件上传 
export function fileUpload (data) {
  return service({
    url: '/certification/api/front/file/upload',
    method: 'POST',
    data: data
  })
}
// 根据父级id获取列表，省级时父级id为1
export function getListByParentId (data) {
  return service({
    url: '/certification/api/front/province/getListByParentId',
    method: 'GET',
    params: data
  })
}
// 用户登录-新
export function newLogin (data) {
  return service({
    url: '/certification/api/newLogin',
    method: 'post',
    data
  })
}

// 获取滑动验证码的图片
export function getImgSwipe (data) {
  return service({
    url: '/certification/apilogin/getImgSwipe',
    method: 'get',
    params: data
  })
}

// 提交验证滑动验证码结果，返回0失败，1成功，2超过规定次数需要刷新
export function rstImgSwipe (data) {
  return service({
    url: '/certification/api/login/rstImgSwipe',
    method: 'post',
    data
  })
}

// 清空登录信息
export function quit (data) {
  return service({
    url: '/certification/api/login/quit',
    method: 'get',
    params: data
  })
}


// 登录用户信息
export function getCenterInfo (data) {
  return service({
    url: '/certification/api/open/center/getCenterInfo',
    method: 'get',
    params: data
  })
}

// 登录展示状态
export function getViewStatus (data) {
  return service({
    url: '/certification/api/thirdLevelPage/comment/getViewStatus',
    method: 'get',
    params: data
  })
}

// 是否生成预览地址
export function onlinePreview (data) {
  return service({
    url: '/certification/api/open/onlinePreview',
    method: 'get',
    params: data
  })
}

// 课程概览
export function ucCourseOverView (data) {
  return service({
    url: '/certification/api/uccourse/ucCourseOverView',
    method: 'get',
    params: data
  })
}

// 获取学段列表
export function getScStages (data) {
  return service({
    url: '/certification/api/uccourse/getScStages',
    method: 'get',
    params: data
  })
}

// 获取学科列表
export function getSubjects (data) {
  return service({
    url: '/certification/api/uccourse/getSubjects',
    method: 'get',
    params: data
  })
}

// 获取来源列表
export function getSources (data) {
  return service({
    url: '/api/uccourse/getSources',
    method: 'get',
    params: data
  })
}

// 获取课程列表
export function getUcCourses (data) {
  return service({
    url: '/api/uccourse/getUcCourses',
    method: 'get',
    params: data
  })
}

// 根据Excel中信息导入课程
export function addUcCourse (data) {
  return service({
    url: '/api/uccourse/addUcCourse',
    method: 'post',
    data
  })
}

// 更新课程信息
export function saveOrUpdateUcCourse (data) {
  return service({
    url: '/api/uccourse/saveOrUpdateUcCourse',
    method: 'post',
    data
  })
}

// 草稿保存课程信息
export function saveDraft (data) {
  return service({
    url: '/api/uccourse/saveDraft',
    method: 'post',
    data
  })
}

// 详情、编辑回显
export function getCourseById (data) {
  return service({
    url: '/api/uccourse/getCourseById',
    method: 'get',
    params: data
  })
}

// 非常规处理
export function assistGetAssistList (data) {
  return service({
    url: '/api/projectConfigurations/assist/getAssistList',
    method: 'get',
    params: data
  })
}
// 菜单列表
export function resourceNavList (data) {
  return service({
    url: '/api/open/resourceNavList',
    method: 'get',
    params: data
  })
}