import axios from 'axios'
import router from '@/router'

// 创建axios
const service = axios.create({
  baseURL: window.location.origin,
});


/*
* 设置超时时间和跨域是否允许携带凭证
*/
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = true;


/*
* 设置请求传递数据的格式（看服务器要求什么格式）
* x-www-form-urlencoded
*/
// axios.defaults.headers['Content-type'] = 'application/x-www-form-urlencoded';
// axios.defaults.transformRequest = data => qs.stringify(data);


/*
* 设置请求拦截器
* 客户端发送请求 -> [请求拦截器] -> 服务器
* TOKEN校验（JWT）：接受服务器返回的token，存储到vuex/本地存储中，
* 每一次向服务器发请求，我们应该把token带上
*/

service.interceptors.request.use(config => {
  const token = sessionStorage.getItem('token');
    if (token) {
        config.headers['Authori-certificate'] = `${token}`;
    }
  return config;
}, error => {
  return Promise.reject(error)
});


/*
* 响应拦截器
* 服务器返回信息 -> [拦截的统一处理] -> 客户端js获取到信息
*/
/*// // 很少有公司用除特殊情况开头2 or 3 都成功
axios.defaults.validateStatus = status => {
    //自定义响应成功的HTTP状态码
    return /^(2|3)\d{2}$/.test(status);
}*/
service.interceptors.response.use(response => {
  // console.log('response', response)
  if(response.data.state == 401) {
    sessionStorage.removeItem('token');
    router.replace('/login')
  }
  return response.data;
}, error => {

  let { response } = error
  if (response) {
    //=>服务器最起码返回结果了

    console.log(response.status)
    switch (response.status) {
      case 401: // 当前请求需要用户验证（一般是未登录）
        break;
      case 403: // 服务器已经理解请求，但是拒绝执行它（一般是token过期）
        //跳转到登录页
        break;
      case 404: //请求失败，请求所希望的资源未在服务器上发现
        break;
      case 500:
        break;
      case 502:
        break;
    }
    return Promise.reject(error)
  } else {
    //=>服务器连结果都没有返回
    if (!window.navigator.onLine) {
      //断网处理：可以跳转到断网页面
      return;
    }
    return Promise.reject(error);
  }
})

export default service;
